<template>
  <main v-if="rack">
    <PageTitleContainer>
      <template #default>
        <PageTitle>{{ rack.name }}</PageTitle>
      </template>
      <template #actions>
        <router-link class="btn btn-outline-primary" :to="{ name: 'warehouse.racks.edit', params: { id: rack.id } }">Modifica</router-link>
      </template>
    </PageTitleContainer>

    <div class="container">
      <div>
        <p><strong>Name</strong>: {{ rack.name }}</p>
        <p><strong>Numero di ripiani</strong>: {{ rack.nbShelves }}</p>
      </div>

      <AwlTable v-if="rack && rack.shelves.length > 0" :heading="tableHeadings" :rows="rack.shelves" striped>
        <template #column-key-producers="{ value }">
          <template v-if="value.length > 0">
            <template v-for="producer in value">
              {{ producer.name }},
            </template>
          </template>
          <template v-else>Nessun produttore su questo ripiano</template>
        </template>

        <template #actions-content="{ row }">
          <ActionAdd @click.prevent="openModal(row.id)" />
        </template>
      </AwlTable>
      <p v-else>Nessun ripiano trovato</p>

      <AddProducerToRackShelfModal v-if="$modals.add" :producers="allProducers" @close="$closeModal('add')" @producerSelected="addProducer" />
    </div>
  </main>
  <span v-else></span>
</template>

<script>

import $api from '@/libs/OAuth2/services/api.js';
import ModalsManagerMixin from '@/libs/Modals/mixins/manager.js';

import PageTitleContainer from '@/views/components/Private/PageTitleContainer.vue';
import PageTitle from '@/views/components/Private/PageTitle.vue';
import AwlTable from '@/libs/Table/components/AwlTable.vue';
import ActionAdd from '@/libs/Table/components/Actions/ActionAdd.vue';
import AddProducerToRackShelfModal from '@/views/components/Modals/AddProducerToRackShelfModal.vue';

function beforeRouteEnterOrUpdate (to, from, next) {
  if (!to?.params?.id) {
    return next(from);
  }

  Promise.all([
    $api.fetchRack(to.params.id),
    $api.listProducers(),
  ])
    .then(res => next(vm => {
      vm.rack = res[0]?.data;
      vm.allProducers = res[1]?.data?.producers || [];
    }))
    .catch(() => next(from))
  ;
}

export default {
  mixins: [ModalsManagerMixin],
  components: { ActionAdd, AwlTable, PageTitleContainer, PageTitle, AddProducerToRackShelfModal },
  data () {
    return {
      loading: false,
      rack: null,
      allProducers: null,
      shelfSelected: null,
    };
  },
  computed: {
    tableHeadings () {
      return [
        { key: 'name', label: 'Nome' },
        { key: 'producers', label: 'Produttori' },
      ];
    },
  },
  methods: {
    openModal (shelfId) {
      this.shelfSelected = shelfId;
      this.$openModal('add');
    },
    addProducer (producerId) {
      this.$api
        .addProducerToRackShelf(this.shelfSelected, producerId)
        .then(res => {
          this.rack = res.data;
        })
      ;
    },
  },
  beforeRouteEnter: beforeRouteEnterOrUpdate,
  beforeRouteUpdate: beforeRouteEnterOrUpdate,
};

</script>
